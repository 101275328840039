import React from 'react'
import "./Plans.css"
import {plansData} from "../../data/plansData"
import whiteTick from "../../assets/whiteTick.png"

const Plans = () => {
  return (
    <div className='plans-container' id='plans'>
      <div className="blur blur-p-1"></div>
      <div className="blur blur-p-2"></div>

      <div className="programs-header" style={{gap:"2rem"}}>
        <span className='stroke-text'>Ready to start</span>
        <span>Your Journey</span>
        <span className='stroke-text'>Now Withus</span>
      </div>
      {plansData.map((plans , i) => {
        <div className="plan" key={i}>
            {plans.icon}
            <span>{plans.name}</span>
            <span>$ {plans.price}</span>
            <div className="features">
                {plans.features.map((feature, i) =>{
                    <div className="feature">
                        <img src={whiteTick} alt="" />
                        <span key={i} >{feature}</span>
                    </div>
                })}
                
            </div>
            <div><span>See more benefits</span></div>
            <button className='btn'>Join Now</button>
        </div>
        
      })}
    </div>
  )
}

export default Plans
