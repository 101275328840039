import './App.css';
import Hero from './component/Hero/Hero';
import Program from './component/Program/Program';
import Reason from './component/Reasons/Reason';
import Plans from './component/plans/Plans';
import Testimonial from './component/Testimonials/Testimonial';
import Join from './component/Join/Join';
import Footer from './component/Footer/Footer';

function App() {
  return (
    <div className="App">
       <Hero />
       <Program />
       <Reason />
       <Plans />
       <Testimonial />
       <Join />
       <Footer />
    </div>
  );
}

export default App;
